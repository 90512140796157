import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import FirebaseApp from './Firecontact';

const Testimonial = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        const fetchTestimonials = async () => {
            const db = FirebaseApp.firestore();
            try {
                const querySnapshot = await db.collection('testimonials').get();
                const fetchedData = [];
                querySnapshot.forEach((doc) => {
                    fetchedData.push({ id: doc.id, ...doc.data() });
                });
                setTestimonials(fetchedData);
            } catch (error) {
                console.error("Error fetching testimonials: ", error);
            }
        };

        fetchTestimonials();
    }, []);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <section className="py-4 pb-5 px-3 px-md-0 bg-white">
                <div className="container px-0">
                    <div className="row text-center">
                        <h2 className="text-dark pb-10 fw-semibold">Testimonial</h2>
                        <p>Don't just take our word for the level of service we provide - read what our buyers and sellers have to say!</p>
                    </div>
                    <div className="col-lg-12">
                        <Slider {...settings}>
                            {testimonials.map((testimonial) => (
                                <div className="d-flex h-100" key={testimonial.id}>
                                    <div className="shadow-box p-25 m-2">
                                        <div className="d-flex justify-content-between">
                                            <div className="testimonial-info">
                                                <div className="image d-flex align-self-center align-items-center">
                                                    {testimonial.image && <img src={testimonial.image} className="w-25 rounded-circle" alt={testimonial.name} />}
                                                    <div className="meta ms-2">
                                                        <h6>{testimonial.name}</h6>
                                                        <div className="star-rating mt-2" style={{ color: 'gold' }}>
                                                            {/* Assuming a rating system, customize as needed */}
                                                            {[...Array(5)].map((_, index) => (
                                                                <i key={index} className="bi bi-star-fill"></i>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content mt-10">
                                            <p>{testimonial.message}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Testimonial;
