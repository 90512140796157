import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import firebaseApp from "./Firecontact";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const validatePhoneNumber = (phoneNumber) => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phoneNumber);
};

const Products = () => {
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    phone: "",
    businessName: "",
    businessLink: "",
    city: "",
    address: "",
    forCard: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const db = firebaseApp.firestore();
    db.collection("product")
      .get()
      .then((querySnapshot) => {
        const fetchedProducts = [];
        querySnapshot.forEach((doc) => {
          fetchedProducts.push({ id: doc.id, ...doc.data() });
        });
        setProducts(fetchedProducts);
      })
      .catch((error) => {
        console.error("Error fetching products: ", error);
      });
  }, []);

  const openModal = (product) => {
    setSelectedCard(product.productName);
    setFormData({ ...formData, forCard: product.productName });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setFormData({
      firstName: "",
      phone: "",
      businessName: "",
      businessLink: "",
      city: "",
      address: "",
      forCard: "",
    });
    setShowModal(false);
  };

  const handleSubmit = () => {
    const errors = {};

    if (formData.firstName.trim() === "") {
      errors.firstName = "First name is required.";
    }
    if (formData.businessName.trim() === "") {
      errors.businessName = "Business name is required.";
    }
    if (formData.businessLink.trim() === "") {
      errors.businessLink = "Business link is required.";
    } else if (!isValidURL(formData.businessLink)) {
      errors.businessLink = "Please enter a valid URL.";
    }
    if (formData.phone.trim() === "") {
      errors.phone = "Phone number is required.";
    } else if (!validatePhoneNumber(formData.phone)) {
      errors.phone = "Please enter a valid phone number (10 digits).";
    }
    if (formData.city.trim() === "") {
      errors.city = "City is required.";
    }
    if (formData.address.trim() === "") {
      errors.address = "Address is required.";
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const productData = {
      ...formData,
      id: Date.now(),
      Date: new Date().toLocaleString(),
    };

    const db = firebaseApp.firestore();
    db.collection("Product_orders")
      .add(productData)
      .then(() => {
        toast.success("Order submitted successfully!", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 2000,
        });
        handleClose();
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const isValidURL = (url) => {
    const pattern = /^(https?:\/\/)?([a-z0-9.-]+)\.[a-z]{2,6}(\/[^\s]*)?$/i;
    return pattern.test(url);
  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f7fc" }}>
        <section className="pb-15 pt-35 bg-white px-3 px-lg-0 service-categories">
          <div className="container">
            <div className="text-center">
              <h2 className="mb-10 fw-semibold">NFC Card Services</h2>
              <p className="text-center col-md-10 pb-25 m-auto">
                In an era where speed and security are paramount, NFC cards are
                redefining the way we handle transactions.
              </p>
            </div>
            <div className="row featureCategorySlider pt-1">
              {products.map((product) => (
                <div
                  key={product.id}
                  className="col-xl-2 col-md-3 col-6 mb-25 rounded"
                >
                  <div
                    className="card rounded p-0"
                    onClick={() => openModal(product)}
                  >
                    <img
                      src={product.image}
                      alt={product.productName}
                      className="rounded-top"
                    />
                    <div
                      className="border-yellow p-2 d-flex flex-column align-items-center justify-content-center text-center rounded"
                      style={{ background: "#f9f9f9" }}
                    >
                      <span className="fs-6 text-dark fw-500">
                        {product.productName}
                      </span>
                      <div className="d-flex flex-wrap justify-content-center">
                        <span
                          className="text-danger text-decoration-line-through pe-1"
                          style={{ fontSize: "14px" }}
                        >
                          ₹{product.price}
                        </span>
                        <span
                          className="text-success"
                          style={{ fontSize: "14px" }}
                        >
                          <div>(Offer: {product.offerPrice}%)</div>
                        </span>
                      </div>
                      <div>
                        <span className="fs-6 fw-bold">
                          ₹{product.finalPrice}
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn w-100 fw-bold rounded-0 rounded-bottom border-none"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #00d2ff 0%, #3a7bd5 51%, #00d2ff 100%)",
                        textAlign: "center",
                        textTransform: "uppercase",
                        transition: "0.5s",
                        backgroundSize: "200% auto",
                        color: "#fff",
                        boxShadow: "0 0 20px #eee",
                        borderRadius: "10px",
                        display: "block",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundPosition =
                          "right center";
                        e.currentTarget.style.color = "#fff";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundPosition =
                          "left center";
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Now for {selectedCard}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Fill out the form below to place your order for {selectedCard}.</p>
          {validationErrors.requiredFields && (
            <div className="alert alert-danger">
              {validationErrors.requiredFields}
            </div>
          )}
          <div className="mb-3">
            <input
              type="text"
              name="firstName"
              className={`form-control ${
                validationErrors.firstName ? "is-invalid" : ""
              }`}
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="First Name"
            />
            {validationErrors.firstName && (
              <div className="invalid-feedback">
                {validationErrors.firstName}
              </div>
            )}
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="businessName"
              className={`form-control ${
                validationErrors.businessName ? "is-invalid" : ""
              }`}
              value={formData.businessName}
              onChange={handleInputChange}
              placeholder="Business Name"
            />
            {validationErrors.businessName && (
              <div className="invalid-feedback">
                {validationErrors.businessName}
              </div>
            )}
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="businessLink"
              className={`form-control ${
                validationErrors.businessLink ? "is-invalid" : ""
              }`}
              value={formData.businessLink}
              onChange={handleInputChange}
              placeholder="Business Link"
            />
            {validationErrors.businessLink && (
              <div className="invalid-feedback">
                {validationErrors.businessLink}
              </div>
            )}
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="phone"
              className={`form-control ${
                validationErrors.phone ? "is-invalid" : ""
              }`}
              maxLength={10}
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Phone"
            />
            {validationErrors.phone && (
              <div className="invalid-feedback">{validationErrors.phone}</div>
            )}
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="city"
              className={`form-control ${
                validationErrors.city ? "is-invalid" : ""
              }`}
              value={formData.city}
              onChange={handleInputChange}
              placeholder="City"
            />
            {validationErrors.city && (
              <div className="invalid-feedback">{validationErrors.city}</div>
            )}
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="address"
              className={`form-control ${
                validationErrors.address ? "is-invalid" : ""
              }`}
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Address"
            />
            {validationErrors.address && (
              <div className="invalid-feedback">{validationErrors.address}</div>
            )}
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="forCard"
              className="form-control"
              value={formData.forCard}
              readOnly
              placeholder="Selected Card"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Order Now
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Products;