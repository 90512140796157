import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Products from './Products';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const Product = () => {
  return (
    <>
      <div style={{ backgroundColor: '#f7f7fc' }}>
        <Header name='Product' />
        <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
          <div className="container">
            <div className="d-flex justify-content-between align-items-center align-self-center">
              <h3 className="text-dark fw-600 d-none d-md-block pb-0">
                Product <span className="text-orange">Us</span>
              </h3>
              <ol className='d-flex justify-content-center align-items-center'>
                <li>
                  <Link to={'/Home'} className='text-orange'>Home</Link>
                </li>
                <span className='mx-2'>/</span>
                <li>Product</li>
              </ol>
            </div>
          </div>
        </section>
        <Products />
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
};

export default Product;
