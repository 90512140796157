import Header from './Header'
import Accordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import Footer from './Footer';
 
import Ourservices from './Ourservices';
import Footerbrand from './Footerbrand';
import { Link } from 'react-router-dom';

const Aboutus = () => {

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='bg-gray'>
      <Header name='About-us' />
      
    <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
      <div class="container">

        <div class="d-flex justify-content-between align-items-center align-self-center">
           <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
              About <span className="text-orange">Us
              </span></h3>
          <ol className='d-flex justify-content-center align-items-center '>
            <li>
               <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span> 
            <li> About Us</li>
          </ol>
        </div>
      </div>
    </section> 
       
      <Ourservices />
      <section className="py-4 py-md-5">
        <div className="container  px-3 px-md-0">
          <div className="row justify-content-between align-items-centers">
            <div className="col-lg-5 col-md-6 col-12 align-self-center">
              <h5>Our Vision</h5>
              <h4 className="mb-20 text-dark fw-semibold">Simplifying complex businesses</h4>
              <p className="mb-20">Design isn't simply aesthetics. It's giving form to ideas, translating complex thoughts into visual clarity that moves hearts and minds. It's thinking, made visible.
              </p>

              <div className="mt-20 mb-20">
                <a href='https://api.whatsapp.com/send?phone=+919925615191&amp;text=Hi+Ghanshyam,+I+want+some+more+information+about+your+services.+Please+reply+soon.' className="btn-main"><i className="lni lni-whatsapp me-2  "></i>Chat whatsapp</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  ">
              <div className="row">
                <div className="col-md-6 col-6   mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count1">2K +</span>
                    </h4>
                  <p className="font-weight-normal">Card Designing</p>
                </div>
                <div className="col-md-6 col-6 mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count2"  >750 +</span>
                  </h4>
                  <p className="font-weight-normal">Product Delivered</p>
                </div>
                <div className="col-md-6 col-6 mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count3"  >12 +</span>
                  </h4>
                  <p className="font-weight-normal">Our Team</p>
                </div>
                <div className="col-md-6 col-6 mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count4"  >3 +</span>
                  </h4>
                  <p className="font-weight-normal">Years of Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 

      <section className="pt-35 pb-35 px-3 px-md-0 bg-white ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h4 className=" text-center mb-20  "><i className="lni lni-question-circle text-dark" /><span className="ps-3 text-dark fw-semibold">FAQs for Business</span></h4>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                  How do I make a digital business card?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  It’s simple? You just need to get in touch with the experts at Touch Cards so that you can avail yourself of the ages of experience that we have earned. Right from Business Card Design to Business Card Printing, we offer you complete technical expertise to get you the best digital business cards.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography  >Which digital business card is best?</Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  The answer can be slightly technical. It depends on various things that vary from one user to another. At Touch Cards, our experts recommend you get in touch with them online or offline and get the right information before you decide which cards can serve you the most aptly.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography  >
                  Are digital business cards worth it?
                  </Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Certainly Yes! The utilities of Digital Name Cards in Singapore have increased their demands all over. You can always prefer getting one to ease your communication with your clients/patrons.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography>Are digital business cards a good idea?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  You may not have any doubt about them as far as their utilities are concerned.  These digital business cards assist professionals to create the first impression for a sustainable business relationship with their customers. You can always get in touch with the experts at Touch Cards to clear all the confusions that you may have in the most unique manner.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography>What is the need for digital business cards?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Well, many customers ask these questions from us when they reach us for the first time. We assure them of the following advantages that make these cards an absolute
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography>What is the need for digital business cards?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Well, many customers ask these questions from us when they reach us for the first time. We assure them of the following advantages that make these cards an absolute</p>
                    <li>Card Customization</li>
                    <li>
                    SEO-Friendly Features
                    </li>
                    <li>Card Redirection Facility</li>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <Footerbrand />
      <Footer />
    </div >
  )
}

export default Aboutus
