import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Responsive from './assets/image/Responsive Website.jpg';
import CMS from './assets/image/cms.png';
import Maintenance from './assets/image/app-develop.png';
import Androidapp from './assets/image/blog-app.jpg';
import iOS from './assets/image/Local-app.png';
import ecommersApp from './assets/image/E-Commerce.png';
import SEO from './assets/image/SEO.png';
import Encourages from './assets/image/ui-develop.png';
import Increase from './assets/image/Increase.png';
import { Link } from 'react-router-dom';



const Services = () => {


  return (
    <div style={{ backgroundColor: '#f7f7fc' }}>
      <Header name='Services' />
      
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
      <div class="container">

        <div class="d-flex justify-content-between align-items-center align-self-center">
           <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
           Services <span className="text-orange">Us
              </span></h3>
          <ol className='d-flex justify-content-center align-items-center '>
            <li>
               <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span> 
            <li> Services </li>
          </ol>
        </div>
      </div>
    </section> 
             <section className="bg-white py-4 py-md-5">
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
              <h4 className="mb-20 text-dark fw-semibold">Responsive Website Redesign</h4>
              <li>To maintain mobile SEO friendliness should consider a web makeover.</li>
              <li>A responsive website redesign liontouchwebpark company can improve website.</li>
              <li>The goal is to generate leads traffic through an enhanced website.</li>

              {/* <button type="button" className="btn-main ">Explore Liontouch</button> */}
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center">
              <img src={Responsive} className="w-75 mb-3 mb-md-0" alt="Responsive Website" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 py-md-5"  >
        <div className="container  px-3 px-md-0">
          <div className="row justify-content-between align-items-centers">
            <div className="col-lg-6 col-md-6 col-12  text-center">
              <img src={CMS} className="w-50 mb-4 mb-md-0" alt="CMS Website" />
            </div>
            <div className="col-lg-5 col-md-6 col-12 align-self-center">
              <h4 className="mb-20 text-dark fw-semibold">CMS Website Development</h4>

              <li>CMS is a vital process for maintaining a dynamic and engaging website.</li>
              <li>It ensures the website stays current and appealing to attract more customers.</li>
              <li>Website owners have login access to update and modify content.</li>
              {/* <div className="mt-20">
                <button type="button" className="btn-main">Now join Ourteam</button>
              </div> */}
            </div>

          </div>
        </div>
      </section>
      <section className="bg-white py-4 py-md-5">
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
              <h4 className="mb-20 text-dark fw-semibold">Website Maintenance</h4>
              <li>Continuous updates attract search engine spiders lead generation.</li>
              <li>Our web maintenance team handles these tasks to maintain your online presence.</li>
              <li>We stay of algorithms to keep your site on top of Search Engine Result Pages (SERPs).</li>
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center">
              <img src={Maintenance} className="w-50 mb-4 mb-md-0" alt="Maintenance" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 py-md-5"  >
        <div className="container  px-3 px-md-0">
          <div className="row justify-content-between align-items-centers">
            <div className="col-lg-6 col-md-6 col-12 text-center">
              <img src={Androidapp} className="w-50 mb-4 mb-md-0" alt="Android App" />
            </div>
            <div className="col-lg-5 col-md-6 col-12 align-self-center">
              <h4 className="mb-20 text-dark fw-semibold">Android app development</h4>

              <li>We listen to your needs and design an app that works seamlessly.</li>
              <li>Our developers are masters of the latest Android tools and platforms.</li>
              <li>We create visually friendly experiences with your users.</li>
              {/* <div className="mt-20">
                <button type="button" className="btn-main">Now join Ourteam</button>
              </div> */}
            </div>

          </div>
        </div>
      </section>
      <section className="bg-white py-4 py-md-5" >
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
              <h4 className="mb-20 text-dark fw-semibold">SEO Leads to Better User Experience</h4>
              <li>Elevate website for optimal user experience.</li>
              <li>Ensure easy navigation for improved usability.</li>
              <li>Optimize site for mobile-friendly experience.</li>
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center">
              <img src={SEO} className="w-50 mb-4 mb-md-0" alt="Digital Marketing" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 py-md-5">
        <div className="container  px-3 px-md-0">
          <div className="row justify-content-between align-items-centers">
            <div className="col-lg-6 col-md-6 col-12 text-center   ">
              <img src={Encourages} className="w-50 mb-4 mb-md-0" alt="SEO" />
            </div>
            <div className="col-lg-5 col-md-6 col-12 align-self-center">
              <h4 className="mb-20 text-dark fw-semibold">SEO Also Encourages Local Users</h4>

              <li>Local research often leads customers to visit physical stores.</li>
              <li>Example: A user Googles "Best SEO agency in Surat.</li>
              <li>The user selects a top result and becomes a customer.</li>
              {/* <div className="mt-20">
                <button type="button" className="btn-main">Now join Ourteam</button>
              </div> */}
            </div>

          </div>
        </div>
      </section>
      <section className="bg-white py-4 py-md-5"  >
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
              <h4 className="mb-20 text-dark fw-semibold">SEO Increase Position on SERP</h4>
              <li>Top rankings indicate industry leadership to customers.
              </li>
              <li>Popularity is evident from first, second, or third positions.</li>
              <li>We stay of algorithms to keep your site on top of Search Engine Result Pages (SERPs).</li>
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center">
              <img src={Increase} className="w-75 mb-3 mb-md-0" alt="Increase" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-4  py-md-5" >
        <div className="container  px-3 px-md-0">
          <div className="row justify-content-between align-items-centers">
            <div className="col-lg-6 col-md-6 col-12  text-center">
              <img src={ecommersApp} className="w-50 mb-4 mb-md-0" alt="E-Commerce" />
            </div>
            <div className="col-lg-5 col-md-6 col-12 align-self-center">
              <h4 className="mb-20 text-dark fw-semibold">E-Commerce App Development</h4>

              <li>We craft premium e-commerce apps and websites for clients worldwide.</li>
              <li>Our experts across diverse industries fuel our understanding of your challenges and opportunities.</li>
              <li>We're the trusted e-commerce development partner for all business sizes .</li>
              {/* <div className="mt-20">
                <button type="button" className="btn-main">Now join Ourteam</button>
              </div> */}
            </div>

          </div>
        </div>
      </section>
      <section className=" py-4 bg-white py-md-5"  >
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
              <h4 className="mb-20 text-dark fw-semibold">iOS app development</h4>
              <li>We leverage the latest iOS tools to build powerful and innovative apps.</li>
              <li>Our expert passion is for crafting premium mobile apps.</li>
              <li>We offer ongoing support and guidance from the iOS app store.</li>
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center">
              <img src={iOS} className="w-50 mb-4 mb-md-0" alt="IOS App " />
            </div>
          </div>
        </div>
      </section>
      {/* <Footerbrand /> */}
      <Footer />
    </div>
  )
}

export default Services
