

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';  // <----
let firebaseApp;
SetupFirebase();

/**
* Firebase Initialization Function
* This must be called before any firebase query
*/
function SetupFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyC-PPDzjfZ_m5QWsoVYCFbQUhIn0EmhJWA",
        authDomain: "nfccard9r.firebaseapp.com",
        databaseURL: "https://nfccard9r-default-rtdb.firebaseio.com",
        projectId: "nfccard9r",
        storageBucket: "nfccard9r.appspot.com",
        messagingSenderId: "276027877526",
        appId: "1:276027877526:web:31cefc88281eb649327ab7",
        measurementId: "G-8STPXJS02R"
    };
    // Initialize Firebase
    firebaseApp = firebase.initializeApp(firebaseConfig);
}

export default firebaseApp;