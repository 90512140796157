import React, { useEffect, useState } from 'react';
import FirebaseApp from './Firecontact';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Footerbrand from './Footerbrand';

const BlogDetail = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const fetchBlog = () => {
            const db = FirebaseApp.firestore();
            db.collection('Blog').doc(id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        setBlog({ id: doc.id, ...doc.data() });
                    } else {
                        console.error('No such document!');
                    }
                })
                .catch((error) => {
                    console.error('Error getting document:', error);
                });
        };

        fetchBlog();
    }, [id]);

    return (
        <div>
            <Header name='blog-detail' />
            <div className="container py-5">
                {blog ? (
                    <>
                        <img src={blog.image} alt={blog.title} style={{ width: '100%' }} />
                        <p>Date: {new Date(blog.date).toLocaleDateString()}</p>
                        <h2>{blog.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
            <Footerbrand />
            <Footer />
        </div>
    );
};

export default BlogDetail;
