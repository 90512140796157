import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Footerbrand from './Footerbrand';
import { Link } from 'react-router-dom';
import FirebaseApp from './Firecontact';
import Development from './assets/image/Business Development Advice.jpg';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.bubble.css'; // Import bubble theme

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = () => {
      const db = FirebaseApp.firestore();
      db.collection('Blog')
        .get()
        .then((querySnapshot) => {
          const fetchedData = [];
          querySnapshot.forEach((doc) => {
            fetchedData.push({ id: doc.id, ...doc.data() });
          });
          setBlogs(fetchedData);
        })
        .catch((error) => {
          console.error('Error getting documents: ', error);
        });
    };

    fetchBlogs();
  }, []);

  const truncateText = (text, length) => {
    return text.length > length ? text.slice(0, length) + '...' : text;
  };

  return (
    <div>
      <Header name='blog' />
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-dark fw-600 d-none d-md-block pb-0">Blog & Best Advice</h3>
            <ol className='d-flex justify-content-center align-items-center'>
              <li>
                <Link to={'/Home'} className='text-orange'>Home</Link>
              </li>
              <span className='mx-2'>/</span>
              <li>Blog & Best Advice</li>
            </ol>
          </div>
        </div>
      </section>
      <section className="mb-35 mt-35 px-3 p-md-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12">
              
              <div className="row mt-15">
                {blogs.map((blog) => (
                  <div key={blog.id} className="col-md-4 mb-20">
                    <div className="p-3 border rounded bg-white">
                      {blog.image && (
                        <img
                          src={blog.image}
                          className="rounded-1 mb-2"
                          alt={blog.title}
                        />
                      )}
                      <div>
                        <h2 className="fw-semibold">{blog.title}</h2>
                        <ReactQuill
                          value={truncateText(blog.content || "No content available", 100)}
                          readOnly
                          theme="bubble"
                          className="mt-3 mb-2"
                        />
                        <Link to={`/blog/${blog.id}`} className="btn-main w-100 py-2 rounded-1 border-0">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerbrand />
      <Footer />
    </div>
  );
}

export default Blog;
