import React from 'react'
import Design from './assets/image/design.png';
import customdesign from './assets/image/customdesign.png';
import WebsiteMaintenance from './assets/image/Website-mintenance.png';
import ECommerce from './assets/image/E-Commerce.png';
import Social from './assets/image/Social.png';
import Secure from './assets/image/Secure.png';

const Ourservices = () => {
  return (
    <section className="bg-white pt-35 pb-35">
    <div className="container">
      <div className="row pb-10 d-flex justify-content-center ">
        <div className="col-md-10 mb-20 text-center">
          <h2 className="fw-semibold pt-0">
            Our Services
          </h2>

        </div>
      </div>
      <div className="row text-center| layout">
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={Design} className="pb-20 w-25" alt="Quality Printing" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Quality Printing</h4>
              <p className="lh-sm fw-light">Finest print, Quality stocks, Precise cutting. We believe quality seriously matter.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={customdesign} className="pb-20 w-25" alt="Timely Delivery" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Timely Delivery</h4>
              <p className="lh-sm fw-light">No printer faster than us . Order today by 9:00pm India time and you can even get it next day.              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={WebsiteMaintenance} className="pb-20 w-25" alt="Website Maintenance" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Variefy of printing</h4>
              <p className="lh-sm fw-light">A variety of printing equipment with the most up-to-date technology to cater for different types of materials and finishing effect.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={ECommerce} className="pb-20 w-25" alt="Flexible with packaging arrangements" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Flexible with packaging arrangements</h4>
              <p className="lh-sm fw-light">It is our utmost priority to ensure customer satisfaction even after products have been delivered.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={Social} className="pb-20 w-25" alt="Social Following" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Social Following</h4>
              <p className="lh-sm fw-light">As well as our web presence we also have a large social following
                to promote to As well as our web presence we also have a large social following
                to promote to.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={Secure}className="pb-20 w-25" alt="Secure" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Secure</h4>
              <p className="lh-sm fw-light">We work hard to provide good quality leads from interested buyers
                that you can convert We work hard to provide good quality leads from interested buyers
                that you can convert.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Ourservices