import React from 'react'
import webdevelop from './assets/image/web-develop.png'
import appdevelop from './assets/image/app-develop.png'
import Marketing1 from './assets/image/marketing girl.jpg';
import Marketinggirl from './assets/image/NFC card.jpg';
import webblog from './assets/image/business.jpg';
import appblog from './assets/image/pramot.jpg';
import NFCcardmockup from './assets/image/google review.png'
import Uiblog from './assets/image/in-post 9.jpg';
import Footer from './Footer';
import Header from './Header';
import { Link } from 'react-router-dom';
import Ourservices from './Ourservices';

import { ToastContainer } from 'react-toastify';
import Footerbrand from './Footerbrand';
import Products from './Products';
import Testimonial from './Testimonial';

const Home = () => {

    return (
        <div className='bg-gray'>
            <Header name='home' />
            <ToastContainer />
            <section className="bg-color px-3 px-md-0">
                <div className="container py-3 py-md-5">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 align-self-center ps-0">
                            <h5 className="pt-15 pb-2 text-center text-md-start" style={{ fontSize: '20px' }}>
                                Networking Made Simple and Swift</h5>
                            <div>
                                <h1 className="fw-600 text-center text-md-start" style={{ fontSize: '54px' }}>
                                    Next Generation <span className="text-orange">Networking
                                    </span></h1>
                                <h3 className="pt-10 text-center text-md-start fw-400 lh-1  ">
                                    An introduction to a technology that’s changing just about everything. </h3>
                                <p className="mt-10 text-center text-md-start">Start advertising your Business opportunity with Create a Website today to boost your Business recruitment and attract more high quality for your business at a lower cost. <span className="text-orange">With 9Rnfc</span></p>
                            </div>
                            <div className="row mt-20 mt-md-3">
                                <div className="col-lg-4  ms-2 ms-md-0 d-grid">
                                    <Link to={'/About-Us'} className="btn-history text-dark py-2" >
                                        <i class="lni lni-bookmark-alt pe-2 "></i> Our Vision
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col justify-content-end">
                            <img src={NFCcardmockup} className=" " alt="NfcCard" />
                        </div>
                    </div>
                </div>
            </section>
            <Products />
            <div className="container px-lg-0 pt-35">
                <div className="row bg-dark text-center rounded-2 pt-2 pb-2 mb-15 mx-auto mx-lg-0">
                    <div className="col">
                        <h5 className="text-white">
                            Just Whatsapp Message <span className="text-orange">Contact Our Team</span> When
                            Calling
                        </h5>
                    </div>
                </div>
            </div>
            <section className="py-4 py-md-5">
                <div className="container  px-3 px-md-0">
                    <div className="row  justify-content-between align-items-center">
                        <div className="col-lg-6 col-md-6 col-12   text-center py-3 py-md-0  ">
                            <img src={Marketing1} className="w-75" alt="business growth girl" />
                        </div>
                        <div className="col-lg-5 col-md-6 col-12 align-self-center">
                            <h4 className="mb-20 text-dark fw-semibold">The World’s leading NFC supplier. We live and breath NFC, delivering NFC Tags, Products, Readers and Solutions to wherever you are in the World. We are able to supply quantities from hundreds of units, to thousands of units, to millions of units. A project is never too big.</h4>
                            <p className="mb-10">With our fantastic and innovative online ordering facility, our customers are able to create orders through our website with ease and efficiency no matter the payment method, saving you the need for endless emails and phone calls.
                            </p>
                            <li>Fast Communication</li>
                            <li>show your Business</li>
                            <li>Speed link provide</li>

                            <div className="mt-20 ">
                                <Link to={'/contact'} className="btn-main">Start Order</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="bg-white py-4 py-md-5">
                <div className="container  px-3 p-lg-0">
                    <div className="row  justify-content-between align-items-center">
                        <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
                            <h4 className="mb-20 text-dark fw-semibold">Plastic Card Printing</h4>
                            <p className="mb-10">All company related cards must be ordered from a company email address. We DO NOT print government, insurance, financial or student related cards under personal accounts.                            </p>
                            <li>Staff Id Card</li>
                            <li>Nfc Business card</li>
                            <li>Employee card</li>
                            <li>Portfilio Cards</li>

                            <Link to={'/contact'} className="btn-main  mt-20">Explore Liontouch</Link>
                        </div>
                        <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center text-lg-end py-3 py-md-0">
                            <img src={Marketinggirl} className="w-75" alt="Plastic Card Printing" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-4">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 align-self-center">
                        </div>
                        <div className="col-lg-10 col-md-12 text-center">
                            <h2 className="text-center fw-bold fs-1 text-dark">
                                Welcome to <span className="text-orange">Business  Growth</span>
                            </h2>
                            <div className="d-flex justify-content-center mt-10">
                                <p className="text-dark fw-500 px-3">Follow Instagram</p>
                                <span className="botd" />
                                <p className="text-dark fw-500 px-3">Generate Leads</p>
                                <span className="botd" />
                                <p className="text-dark fw-500 px-3">Sell Product</p>
                            </div>
                            <p className="text-center   mb-10">  Share business profile and contact info easily by tapping card on devices. No app needed, saves time and makes connections quickly.NFC Business Cards enable instant sharing of contact information with a simple tap or scan.
                            </p>
                        </div>
                        <div className="col-lg-1 col-md-1 align-self-center">
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-9 col-md-12">
                            <div className="shadow-box bg-color p-30 mt-20">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div>
                                            <h3 className=" fs-3 lh-1 mb-20 fw-md text-center text-md-start">Why Create NFC?
                                            </h3>
                                        </div>
                                        <div className="ps-3">
                                            <div className="row">
                                                <div className="col-lg-6">  <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    NFC enables quick, secure transactions.
                                                </p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Effortless payments with NFC technology.</p>
                                                    <p className="text-dark d-flex  mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        NFC simplifies contactless interactions efficiently.</p></div>
                                                <div className="col-lg-6">  <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    Instant data exchange with NFC.
                                                </p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        NFC reduces physical touch requirements.</p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Seamless integration with mobile devices.
                                                    </p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 d-flex justify-content-end  mt-20 mt-lg-0">
                                        <div className=" w-100 ">
                                            <Link to={'/About-us'} className="btn-main w-100 ">Create Card
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12  d-none d-lg-inline align-self-center justifty-contant-center  ">
                            <img src={webdevelop} alt='' />

                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-3 col-md-12    d-none d-lg-inline align-self-center  ">
                            <img src={appdevelop} alt='' />

                        </div>
                        <div className="col-lg-9 col-md-12">
                            <div className="shadow-box bg-color p-30 mt-20">
                                <div className="row ">
                                    <div className="col-lg-8 col-md-8">
                                        <div>
                                            <h3 className="fs-3 lh-1 mb-20  fw-md text-center text-md-start">
                                                Why Create NFC Standee?</h3>
                                        </div>
                                        <div className="ps-3">
                                            <div className="row">
                                                <div className="col-lg-6">  <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    NFC improves security with encryption.
                                                </p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        User-friendly NFC systems enhance convenience.</p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        NFC provides fast transaction processing.
                                                    </p></div>
                                                <div className="col-lg-6">  <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    Durable NFC cards withstand usage.
                                                </p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Hygienic contactless technology with NFC.
                                                    </p>
                                                    <p className="text-dark d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        NFC supports diverse applications effectively.</p></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 d-flex justify-content-end  mt-20 mt-lg-0">
                                        <div className="w-100">
                                            <Link to={'/About-us'} className="btn-main w-100 rounded-2">Build Business New Strategy</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Ourservices />
            <section className="py-4 bg-gray px-2 px-lg-0">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="text-center">
                            <h2 className="fw-semibold">Guides In Business Development</h2>
                            <p className="d-flex justify-content-center mt-15 mb-20">Identify a specific niche or industry where you can excel.Emphasize the importance of responsive web design.Design websites with scalability in mind for future growth.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9 col-sm-6 mb-3  ">
                            <div className="bg-white rounded-2 shadow-box d-grid   p-3">
                                <div><img src={webblog} className="rounded-1  " alt="webdevelopment" /></div>
                                <div className=" mt-3">
                                    <h5>
                                        Understanding NFC Smart Cards: The Future of Secure, Contactless Transactions
                                    </h5>
                                    <hr className="border-orange w-25 opacity-100" />
                                    <p className="lh-sm text-start">In today's fast-paced world, where convenience and security are paramount, NFC smart cards are emerging as a game-changer in various sectors, from finance to access control. But what exactly are NFC smart cards, and why are they gaining so much traction? Let’s delve into the intricacies of NFC technology and explore how these smart cards are shaping the future of secure, contactless transactions.
                                    </p>
                                    <Link to={"/Contact"} className="btn btn-dark  px-4 mt-20 d-grid">Create Smart on Business Cards</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9 col-sm-6 mb-3    ">
                            <div className="bg-white rounded-2 shadow-box d-grid   p-3">
                                <div><img src={appblog} className="rounded-1   " alt="Integrate AI Development" /></div>
                                <div className=" mt-3">
                                    <h5>
                                        "How NFC Cards are Revolutionizing Payment Systems"
                                    </h5>
                                    <hr className="border-orange   w-25 opacity-100" />
                                    <p className="lh-sm text-start">In an era where speed and security are paramount, NFC cards are redefining the way we handle transactions. Here’s a closer look at how NFC cards are transforming payment systems and what makes them so revolutionary.Near Field Communication (NFC) is a subset of Radio Frequency Identification (RFID) technology. It enables communication between devices over short distances. </p>
                                    <Link to={"/Contact"} className="btn btn-dark mt-20 d-grid" >Build Business Cards</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9 col-sm-6   ">
                            <div className="bg-white rounded-2 shadow-box d-grid   p-3">
                                <div><img src={Uiblog} className="rounded-1  " alt="UI Development" /></div>
                                <div className="mt-3">
                                    <h5>
                                        "The Rise of NFC Cards: What They Are and How They're Changing Our Lives"
                                    </h5>
                                    <hr className="border-orange w-25 opacity-100" />
                                    <p className="lh-sm text-start">The process is surprisingly simple yet sophisticated. When an NFC card is brought close to a compatible reader, the card is powered by the reader’s magnetic field, allowing it to transmit data. This swift exchange of information is secure, thanks to encryption and authentication protocols.1.	Contactless Payments: One of the most widespread applications, allowing quick and secure transactions with a simple tap.
                                    </p>
                                    <Link to={"/Contact"} className="btn btn-dark mt-20 d-grid">Build Brand Business</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Testimonial />
            <Footerbrand />
            <Footer />
        </div >
    )
}
export default Home
