import React, { useState } from 'react'
import logofooter from './assets/image/9rwhitelogo.png'
import { Link } from 'react-router-dom';
import firebaseApp from './Firecontact';

// import { ToastContainer, toast } from 'react-toastify';

let edata = [];

const Footer = () => {

    const [email, setEmail] = useState('')
    const [mail, SetOnlyemil] = useState([])

    const validateEmail = (email) => {
        // Use a regex pattern for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const Onlyemail = () => {
        // toast("Your Account is Created");
        let onlymail = {
            Email: email,
            id: Date.now(),
            Date: Date()
        }

        if (!validateEmail(email)) {
            alert('Please enter a valid email address');
            return;
        }
        edata.push(onlymail)
        console.log(onlymail);

        // toast("We will Reply Soon");

        let db = firebaseApp.firestore()
        db.collection("subcribe").add(onlymail)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

        SetOnlyemil(edata);
        setEmail('')
    }

    const today = new Date();
    const year = today.getFullYear();
    return (
        <div>
            {/* <ToastContainer /> */}
            {/* <!--====== FOOTER PART START ======--> */}
            <footer className="footer-area px-3 px-md-0">
                <div className="widget-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-md-7">
                                <div className="footer-widget text-center text-md-start">
                                    <Link to={'/Home'} className="d-inline-block mb-4" >
                                        <img src={logofooter} alt="Liontouch Webpark" className='w-75' />
                                    </Link>
                                    <p className="text-white mb-25">🚀 UPDATE YOUR BUSINESS STRETERGY👉🏻 people will remember when they forget to scan or tap your 9/R smart NFC QR

                                    </p>
                                    <ul className="social d-flex  ps-0">
                                        <li><a href="https://api.whatsapp.com/send?phone=+919925615191&amp;text=Hi+9|R NFC,+I+want+some+more+information+about+your+services.+Please+reply+soon." target='_blank'><i className="lni lni-whatsapp "></i></a></li>
                                        <li><a href="https://www.facebook.com/profile.php?id=61563096922232" target='_blank'><i className="lni lni-facebook-original"></i></a></li>
                                        <li><a href="https://www.instagram.com/9rnfc_com?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank'><i className="lni lni-instagram-original"></i></a></li>
                                        <li><a href="https://www.linkedin.com/in/9r-nfc-b63aaa319" target='_blank'><i className="lni lni-linkedin-original"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 order-md-3 order-xl-2 col-sm-6 ">
                                <div className="footer-widget">
                                    <h4>Information</h4>
                                    <ul className="link ps-0">
                                        <li><Link to={'/Services'}>Smart Business Card</Link></li>
                                        <li><Link to={'/Services'}>Google Indexing</Link></li>
                                        <li><Link to={'/Services'}>Business Reels</Link></li>
                                        <li><Link to={'/Services'}>Business Branding</Link></li>
                                        <li><Link to={'/Services'}>Marketing Services</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 order-md-2 order-xl-1 col-sm-6 ps-2 ps-md-5">
                                <div className="footer-widget">
                                    <h4>About Us</h4>
                                    <ul className="link ps-0">
                                        <li><Link to={'/Services'}>Services</Link></li>
                                        <li><Link to={'/Product'}>Product</Link></li>                                        
                                        <li><Link to={'/About-us'}>About Us</Link></li>
                                        <li><Link to={'/Blog'}>Blog</Link></li>
                                        <li><Link to={'/Career'}>Career</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-4 order-md-4 order-xl-3 col-sm-6 ">
                                <div className="footer-widget">
                                    <h4>Support</h4>
                                    <ul className="link ps-0 ">
                                        <li><Link to={'/Contact'}>Contact Us</Link></li>
                                        <li><Link to={'/Contact'}>Live Sapport  </Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-5 order-md-1 order-xl-4 col-sm-6 ">
                                <div className="footer-widget">
                                    <h4 className='me-2'>  <i class="lni lni-map-marker me-2"></i>Location</h4>
                                    <ul className='ps-0'>
                                        <li><Link>Order Now</Link></li>
                                        <div className="input-group mb-3">
                                            <input className="form-control" type="text" placeholder="Enter Email Id" onChange={(e) => setEmail(e.target.value)} value={email}
                                                aria-label="Recipient's username" aria-describedby="button-addon2" />
                                            <button className="btn text-light " onClick={Onlyemail} style={{ backgroundColor: '#3085de' }} id="button-addon2" type="button"><i
                                                className="lni lni-arrow-right"></i></button>
                                        </div>
                                        <a href='tal:+919925615191'><i class="lni lni-phone me-2"></i>+91 992 561 5191 </a>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
            <div className="container-fluid bg-grey">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="text-center py-2">
                            <p className='mb-0'>Copywrite © {year} all rights reserved by  <a href="#">Liontouch Webpark PVT.</a> </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--====== BACK TOP TOP PART START ======--> */}
            <a href="#" className="back-to-top btn-hover"><i className="lni lni-white lni-chevron-up"></i></a>
            {/* <!--====== BACK TOP TOP PART ENDS ======--> */}

        </div>
    )
}

export default Footer