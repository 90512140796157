import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import Carrer from './assets/image/career.png'
import firebaseApp from './Firecontact';
import { ToastContainer, toast } from 'react-toastify';
import Footerbrand from './Footerbrand';
import { Link } from 'react-router-dom';

let DataCar = [];
const Career = () => {
   
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [portfolioUrl, setPortfolioUrl] = useState('');
  const [selectedTechnology, setSelectedTechnology] = useState('');
  const [cvFile, setCvFile] = useState('');
  const [message, setMessage] = useState('');
  const [pushdata, SetCareer] = useState([])

  const validateEmail = (email) => {
    // Use a regex pattern for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phonenumber) => {
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phonenumber);
  };

  const handleFileChange = (file) => {
    // setshowLoader(true)
    const guid = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return String(s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4());
    }
    let myPromise = new Promise((resolve, reject) => {
      const myGuid = guid();
      const storageUrl = firebaseApp.storage('gs://liontouchwebpark-a5ef9.appspot.com')
      const storageRef = storageUrl.ref();
      console.log('ref : ', storageRef)
      const uploadTask = storageRef.child('Image').child('CvDeveloper').child(myGuid).put(file[0])
      uploadTask.on('state_changed',
        (snapShot) => {
          // console.log(snapShot);
        }, (err) => {
          //catches the errors
          console.log(err)
          reject(err)
        }, () => {

          firebaseApp
            .storage('gs://liontouchwebpark-a5ef9.appspot.com')
            .ref()
            .child('Image')
            .child('CvDeveloper')
            .child(myGuid)
            .getDownloadURL()
            .then(fireBaseUrl => {
              console.log(fireBaseUrl);
              resolve(fireBaseUrl)
            }).catch(err => {
              console.log('error caught', err)
            })
        })
    })
    myPromise.then(url => {
      // console.log(url)
      setCvFile(url)
      // setshowLoader(false)

    }).catch(err => {
      console.log('error caught', err)
    })
  }


  const Careersub = () => {

    let Careerdata = {
      fullName: fullName,
      Email: email,
      Number: phonenumber,
      portfolioUrl: portfolioUrl,
      SelectedTechnology: selectedTechnology,
      CvFile: cvFile,
      Messsage: message,
      id: Date.now(),
      Date: Date()
    }

    if (fullName === '') {
      alert('Please enter Full name');
      return;
    }
    if (!validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }
    if (!validatePhoneNumber(phonenumber)) {
      alert('Please enter a valid phone number 0-9 & 10 Character');
      return;
    }
    if (portfolioUrl === '') {
      alert('Please enter a portfolioUrl link for Http//');
      return;
    }

    if (message === '') {
      alert('Please enter a message');
      return;
    }
    DataCar.push(Careerdata)
    // console.log(Careerdata);  

    let db = firebaseApp.firestore()
    db.collection("freelancers").add(Careerdata)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        toast("Thankyou For Connecting , We Will Meassage Soon.. For Join Team");
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        toast("Plase Try agin");
      });

    SetCareer(Careerdata);
    setFullName('')
    setEmail('')
    setPhoneNumber('')
    setPortfolioUrl('')
    setSelectedTechnology('')
    // setCvFile('')
    setMessage('')


  };
  const handleTechnologyChange = (e) => {
    setSelectedTechnology(e.target.value);
  };

  return (
    <>
      <Header name='Carrer' />
      <ToastContainer />   
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
      <div class="container">
        <div class="d-flex justify-content-between align-items-center align-self-center">
           <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
           Find  hire your team  in   <span className="text-orange"> 24 hours
              </span>
              </h3>
          <ol className='d-flex justify-content-center align-items-center '>
            <li>
               <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span> 
            <li> Find  hire your team  in  24 hours </li>
          </ol>
        </div>
      </div>
    </section> 

      <section className="pt-35 pb-35 px-2 px-lg-2">
        <div className="container p-3">
          <div className="row justify-content-center">
            <h2 className="mb-20 fs-2">Join Our Team Only Freelancers</h2>
            <div className="col-md-6">
              <div className="row mt-10">
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1">Your Email Address*</label>
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1">Phone Number</label>
                  <input
                    maxLength={10}
                    type="text"
                    className="form-control"
                    value={phonenumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1">Portfolio URL</label>
                  <input
                    type="text"
                    className="form-control"
                    value={portfolioUrl}
                    onChange={(e) => setPortfolioUrl(e.target.value)}
                  />
                </div>

                <div className="col-md-12">
                  <label className="text-dark mt-2 mb-1">Only This Technologies</label>
                  <select
                    id="inputState"
                    className="form-control"
                    value={selectedTechnology}
                    onChange={handleTechnologyChange}
                  >
                    <option value="" disabled selected>
                      Select Position
                    </option>
                    <option value="React.js">Web Desiner</option>
                    <option value="Next.js">MERN Stack Developer  </option>
                    <option value="Express.js">Business Development Executive</option>
                    {/* <option value="firebase.js">firebase</option> */}
                    {/* <option value="Sanity.io">Sanity.io</option> */}
                    <option value="Node.js">Node.js</option>
                    <option value="Fluter">Flutter</option>
                    <option value="Android">Android</option>
                  </select>
                </div>
                <label className="text-dark mt-2 mb-1">Upload CV</label>
                <div className="single-form">
                  <div className="upload-input">
                    <input className='form-control'
                      type="file"
                      id="upload"
                      name="upload"
                      onChange={(e) => handleFileChange(e.target.files)}
                    />
                    {/* Add any additional UI elements or messages for file upload if needed */}
                  </div>
                </div>
                <div className="row pb-10">
                  <div className="col-12">
                    <label className="text-dark mt-2 mb-1">Message</label>
                    <textarea className='form-control'
                      name="message"
                      id="message"
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row   ">
                  <div className="col" onClick={Careersub}>
                    <button type="button" className="btn-main w-100 w-md-25">
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 justify-content-center mt-3 align-self-center '>
              <img src={Carrer} className='w-100' alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footerbrand />
      <Footer />
    </>
  )
}

export default Career
