import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import firebaseApp from './Firecontact';
import { ToastContainer, toast } from 'react-toastify';
import Footerbrand from './Footerbrand';
import { Link } from 'react-router-dom';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

const Contact = () => {
  const [companynm, setCompany] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState('You are a Business Man');

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const Contactonsubmit = () => {
    let Contactdata = {
      Company: companynm,
      Number: number,
      Your: selectedValue,
      Messsage: message,
      id: Date.now(),
      Date: new Date().toLocaleString()
    };
    
    if (companynm === '') {
      alert('Please enter company name');
      return;
    }
  
    if (!validatePhoneNumber(number)) {
      alert('Please enter a valid phone number (10 digits)');
      return;
    }
  
    if (message === '') {
      alert('Please enter a message');
      return;
    }
  
    const db = getFirestore(firebaseApp);

addDoc(collection(db, 'Contact_cli'), Contactdata)
  .then((docRef) => {
    console.log('Document written with ID: ', docRef.id);
    toast.success('Thank you for contacting us');
  })
  .catch((error) => {
    console.error('Error adding document: ', error);
  });
  
    setCompany('');
    setNumber('');
    setSelectedValue('You are a Business Man');
    setMessage('');
  };
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Header name='Contact' />
      <ToastContainer />
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="text-dark fw-600 d-none d-md-block pb-0">
              Contact
            </h3>
            <ol className='d-flex justify-content-center align-items-center'>
              <li>
                <Link to='/Home' className='text-orange'>Home</Link>
              </li>
              <span className='mx-2'>/</span>
              <li> Contact </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="pt-35 pb-35 px-2 px-lg-2">
        <div className="container p-3">
          <div className="row justify-content-center">
            <h2 className="mb-20">Contact Us</h2>
            <p>Have a question? Contact us via our contact form and our friendly team will be on hand to help.</p>
            <div className="col-md-6">
              <div className="row mt-10">
                <div className="col-md-12">
                  <label className="text-dark mt-2 mb-1">Business Name</label>
                  <input type="text" className="form-control" onChange={(e) => setCompany(e.target.value)} value={companynm} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="text-dark mt-2 mb-1">Phone Number</label>
                  <input type="text" className="form-control" maxLength={10} onChange={(e) => setNumber(e.target.value)} value={number} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="text-dark mt-2 mb-1">Which Card</label>
                  <select id="inputState" className="form-control" value={selectedValue} onChange={handleSelectChange}>
                    <option value="Which Card">Which Card</option>
                    <option value="WhatsApp">WhatsApp</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Google review">Google review</option>
                    <option value="YouTube">YouTube</option>
                    <option value="Website Link">Website Link</option>
                    <option value="Portfolio">Portfolio</option>
                    <option value="Snapchat">Snapchat</option>
                    <option value="Linkdin">Linkdin</option>
                    <option value="Pinterest">Pinterest</option>
                    <option value="Whatsapp Standee">Whatsapp Standee</option>
                    <option value="Instagram Standee">Instagram Standee</option>
                    <option value="Google review Standee">Google review Standee</option>
                    <option value="Facebook Standee">Facebook Standee</option>
                    <option value="YouTube Standee">YouTube Standee</option>
                    <option value="Portfolio Standee">Portfolio Standee</option>
                    <option value="Website Link Standee">Website Link Standee</option>
                    <option value="Snapchat Standee">Snapchat Standee</option>
                    <option value="LinkedIn Standee">LinkedIn Standee</option>
                    <option value="Pinterest Standee">Pinterest Standee</option>
                  </select>
                </div>
              </div>
              <div className="row pb-10">
                <div className="col-12">
                  <label className="text-dark mt-2 mb-1">Message</label>
                  <textarea name="message" id="message" onChange={(e) => setMessage(e.target.value)} value={message} rows={4} placeholder="Hello My Business is.." />
                </div>
              </div>
              <div className="row pt-0">
                <div className="col">
                  <p className='pb-20'>
                    (Please Note: As we operate as a B2B and have high Minimum Order Quantities, we can only receive enquiries and orders from registered companies).
                  </p>
                  <button type="button" onClick={Contactonsubmit} className="btn-main w-100 w-md-25">
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div className='col-md-6 justify-content-center mt-3'>
              <div className="mapouter" style={{ position: 'relative', textAlign: 'right', height: '500px', width: '100%' }}>
                <div className="gmap_canvas" style={{ overflow: 'hidden', background: 'none!important', height: '100%', width: '100%' }}>
                  <iframe
                    width="100%"
                    height="100%"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.343810188778!2d72.89287757431168!3d21.218210881246463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fbf6e35ee19%3A0x476a6b564855f598!2s9R%20NFCCARD!5e0!3m2!1sen!2sin!4v1726305300755!5m2!1sen!2sin"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    title="Google Map"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerbrand />
      <Footer />
    </div>
  );
};

export default Contact;
