import React from 'react'
import './App.css'; 
import 'bootstrap/dist/css/bootstrap.css'; 
import "slick-carousel/slick/slick.css";
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick-theme.css";
 
import Routercontaner from './Routercontaner';
function App() {
  return (
    <div>
      <Routercontaner />
    </div>
  );
}
export default App;
