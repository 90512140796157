import React from 'react'
import { Link } from 'react-router-dom'
import localapp from './assets/image/Brand nfc.png'


const Footerbrand = () => {
    return (
        <div>
            <section className="pt-20 bg-gray">
                <div className="container">
                    <div className="row ">
                        <div className="col-sm-12 col-md-6 px-2 text-center text-md-start  align-self-center pb-20">
                            <Link to={'/contact'}>
                                <p className="mb-15 fw-semibold font-18">Create Smart Business Cards</p>
                            </Link>
                            <h2 className="fw-semibold text-dark pb-1"  >Connect Your Customer Through <br /> 9|R NFC Cards  and Build a <br />

                                <span className="text-orange"> Relation Of Life </span> Time.
                            </h2>
                            <p className="mb-8 mt-2 font-18">
                                You can Grow your Business With <span className="text-orange">9R NFC CARD</span> </p>

                        </div>
                        <div className="col-sm-12 col-md-6 align-self-end text-center">
                            <img src={localapp} className='w-75 pb-3' alt="NFC Portfolio" />
                        </div>
                    </div>
                </div>
            </section></div>
    )
}

export default Footerbrand